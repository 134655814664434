<template>
  <div>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-btn href="/services/partners" elevation="0" color="transparent">
      <v-icon left> mdi-chevron-left </v-icon>
      <span>Mes partenaires</span>
    </v-btn>
    <v-card outlined class="my-5 mt-10">
      <v-card-text class="profilAgencyAvatar">
        <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
          <v-card-title style="color: white !important">
            <v-img
              class="mr-3"
              max-width="50px"
              @error=";`error`"
              :src="`https://logo.clearbit.com/${partner.website_url}?size=50
          `"
              :alt="partner.website_url"
            ></v-img>
            {{ partner.name }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text class="description">
        <v-row>
          <v-col cols="12" md="12" class="text-left">
            <v-card flat>
              <v-textarea
                v-model="partner.description"
                outlined
                name="input-7-4"
                label="Informations partenaire"
                auto-grow
              ></v-textarea>
              <v-card-actions class="no-padding justify-end mt-n2 mb-n4">
                <v-btn color="EoleYellow" class="white--text" outlined @click="putProductDescription"
                  >Sauvegarder</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="text-right"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider class="mb-5"></v-divider>
    <v-card-title class="no-padding mb-3 mt-5">Nos interlocutors {{ partner.name }}</v-card-title>
    <v-card-text v-if="partner && partner.interlocutors" class="no-padding">
      <v-row>
        <v-col
          class="d-flex text-center"
          v-for="(interlocutor, index) in partner.interlocutors"
          :key="index"
          cols="12"
          md="3"
        >
          <v-card
            outlined
            elevation="3"
            max-width="400"
            class="justify-center text-center d-flex flex-column rounded-xl hover-card-interlocutor"
          >
            <v-row>
              <v-col class="text-right" cols="12" md="12">
                <v-menu left right transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="mr-1 mt-2 text-caption">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link @click="editItem(interlocutor)">
                      <v-list-item-title><v-icon left>mdi-pencil</v-icon>Modifier l'interlocuteur</v-list-item-title>
                    </v-list-item>

                    <v-list-item color="EoleError" link @click="deleteInterlocutor(interlocutor.id)">
                      <v-list-item-title
                        ><v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer l'interlocuteur</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-card-text class="flex-grow-1">
              <v-avatar class="mb-5 interlocutors" size="64" color="EoleBlue">
                <v-icon size="36" color="EoleBlueLighten">mdi-clipboard-account</v-icon>
              </v-avatar>
              <v-card-text class="align-center">
                <p class="text-center">{{ interlocutor.lastname }} {{ interlocutor.firstname }}</p>
                <p class="text-center">{{ interlocutor.function }}</p>
                <v-row>
                  <v-col cols="12" md="12" class="text-left">
                    <strong>N° de téléphone fixe :</strong> {{ interlocutor.phone }} <br />
                    <strong>N° de téléphone portable :</strong> {{ interlocutor.cellphone }} <br />
                    <strong>Adresse email :</strong> {{ interlocutor.mail }} <br />
                  </v-col>
                  <v-col cols="12" md="6" class="text-right"> </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col align-self="start">
                  <v-btn
                    rounded
                    small
                    color="EoleBlue"
                    class="white--text"
                    :href="`mailto:${interlocutor.mail}?subject=Prise de contact&body=Envoyé depuis l'application Mes Services Assurance`"
                    >Envoyer un email</v-btn
                  >
                </v-col>
                <v-col align-self="end">
                  <v-menu right transition="slide-x-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" rounded small color="EoleYellow" class="white--text"
                        >Appeler</v-btn
                      >
                    </template>

                    <v-list>
                      <v-list-item link :href="`tel:${interlocutor.phone}`">
                        <v-list-item-title
                          ><v-icon left>mdi-phone-classic</v-icon>Appeler sur le fixe
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item link :href="`tel:${interlocutor.cellphone}`">
                        <v-list-item-title
                          ><v-icon left>mdi-cellphone</v-icon>Appeler sur le portable</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="d-flex">
          <v-dialog v-model="dialogPutInterlocutor" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Modification interlocuteur pour {{ partner.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          dense
                          outlined
                          :rules="basicRules"
                          v-model="editPartnerInterlocutor.lastname"
                          label="Nom de l'interlocuteur"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          dense
                          outlined
                          :rules="basicRules"
                          v-model="editPartnerInterlocutor.firstname"
                          label="Prénom de l'interlocuteur"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          dense
                          outlined
                          :rules="emailRules"
                          v-model="editPartnerInterlocutor.mail"
                          prepend-inner-icon="mdi-email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          dense
                          outlined
                          v-model="editPartnerInterlocutor.cellphone"
                          prepend-inner-icon="mdi-cellphone"
                          label="N° (portable)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          dense
                          outlined
                          v-model="editPartnerInterlocutor.phone"
                          prepend-inner-icon="mdi-phone-classic"
                          label="N° (fixe)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="12">
                        <v-text-field
                          dense
                          outlined
                          v-model="editPartnerInterlocutor.function"
                          prepend-inner-icon="mdi-account-details"
                          label="Fonction de l'interlocuteur"
                        ></v-text-field> </v-col
                    ></v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn color="warning" text @click="dialogPutInterlocutor = false">Annuler</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="EoleGreen" class="white--text" @click="putPartnerInterlocutor(editPartnerInterlocutor)"
                  ><v-icon left>mdi-history</v-icon> Modifier l'interlocuteur</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogAddInterlocutor" max-width="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                v-bind="attrs"
                v-on="on"
                width="400"
                outlined
                elevation="3"
                class="justify-center text-center d-flex flex-column rounded-xl"
                link
              >
                <v-card-text>
                  <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                </v-card-text>
                <v-card-text> Ajouter un interlocuteur </v-card-text>
              </v-card>
            </template>
            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
              <v-card-text style="background-color: #f1f4f9">
                <v-row>
                  <v-col cols="12" md="3" class="no-padding ml-n2">
                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                      <v-card-title>
                        <v-img
                          :src="require('@/assets/images/logos/anavel_logo.png')"
                          max-height="150px"
                          max-width="120px"
                          alt="logo"
                          contain
                        ></v-img>
                      </v-card-title>
                      <v-card-title class="text-center justify-center muller-capitalized"
                        >Mes Services Assurance</v-card-title
                      >
                      <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                      <v-card-text class="no-padding">
                        <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                        </v-img>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="9" class="no-padding d-flex">
                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                      <v-card-title>
                        <span class="text-h5">Créer un interlocuteur pour la compagnie {{ partner.name }}</span>
                      </v-card-title>
                      <v-card-subtitle
                        >Saisissez les informations dans les champs pour ajouter un nouvel
                        interlocuteur</v-card-subtitle
                      >
                      <v-card-text>
                        <v-form ref="form" lazy-validation>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                dense
                                outlined
                                :rules="basicRules"
                                v-model="partnerInterlocutor.lastname"
                                label="Nom de l'interlocuteur"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                dense
                                outlined
                                :rules="basicRules"
                                v-model="partnerInterlocutor.firstname"
                                label="Prénom de l'interlocuteur"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                dense
                                outlined
                                :rules="emailRules"
                                v-model="partnerInterlocutor.mail"
                                prepend-inner-icon="mdi-email"
                                label="Email"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                dense
                                outlined
                                v-model="partnerInterlocutor.cellphone"
                                prepend-inner-icon="mdi-cellphone"
                                label="N° (portable)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                              <v-text-field
                                dense
                                outlined
                                v-model="partnerInterlocutor.phone"
                                prepend-inner-icon="mdi-phone-classic"
                                label="N° (fixe)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                            ><v-col cols="12">
                              <v-text-field
                                dense
                                outlined
                                v-model="partnerInterlocutor.function"
                                prepend-inner-icon="mdi-account-details"
                                label="Fonction de l'interlocuteur"
                              ></v-text-field> </v-col
                          ></v-row>
                        </v-form>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            postPartnerInterlocutor({
                              lastname: partnerInterlocutor.lastname,
                              firstname: partnerInterlocutor.firstname,
                              mail: partnerInterlocutor.mail,
                              phone: partnerInterlocutor.phone,
                              cellphone: partnerInterlocutor.cellphone,
                              function: partnerInterlocutor.function,
                              partnerId: partner.id,
                            })
                          "
                        >
                          Terminer</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart"
Vue.component("vue-tree", VueTree)

import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

import moment from "moment"
moment.locale("fr")

import io from "socket.io-client"
import { getCookie } from "@/utils/cookies"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    basicRules: [v => !!v || "Champs requis"],

    emailRules: [v => !!v || "Champs requis", v => /.+@.+\..+/.test(v) || "Email invalide"],

    dialogAddInterlocutor: false,
    partnerInterlocutor: {
      lastname: "",
      firstname: "",
      mail: "",
      phone: "",
      cellphone: "",
      function: "",
      partnerId: "",
    },
    dialogPutInterlocutor: false,
    editPartnerInterlocutor: {
      lastname: "",
      firstname: "",
      mail: "",
      phone: "",
      cellphone: "",
      function: "",
      partnerId: "",
    },
    partner: {},
    waitTimeout: false,
    tab: "tab-2",
    document: {
      name: "",
      data: "",
    },
    fileSelected: true,
    dialogUpload: [],
    dialogValidation: [],
    fileUrl: null,
  }),

  async created() {
    this.agencyName = localStorage.getItem("agency")
    const urlParams = new URLSearchParams(window.location.search)
    const idPartner = urlParams.get("id")

    this.fetchPartnerById(idPartner).then(() => {
      document.title = "Fiche partenaire - " + this.partner.name + " | " + this.agencyName
    })
  },

  components: {
    appbar,
    Loading,
  },
  methods: {
    async putPartnerInterlocutor(data) {
      const idAgency = this.$store.state.user.agency_id
      try {
        await fetch(
          `${config.apiUri}/agencies/${idAgency}/partners/${this.partner.partnerId}/interlocutors/${data.id}`,
          {
            method: "PUT",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${getCookie("jwt")}`,
            },

            body: JSON.stringify({
              lastname: data.lastname,
              firstname: data.firstname,
              mail: data.mail,
              phone: data.phone,
              cellphone: data.cellphone,
              function: data.function,
            }),
          },
        )
          .then(resp => {
            if (resp.status === 200) {
              this.$toast.info(`Vous avez bien modifier l'interlocuteur.`, {
                position: "bottom-right",
                timeout: 3000,
              })
            } else {
              this.$toast.error(`Une erreur est survenue.\nImpossible de modifier l'interlocuteur`, {
                position: "bottom-right",
                timeout: 3000,
              })
            }
          })
          .finally(() => {
            this.dialogPutInterlocutor = false
            this.fetchPartnerById(this.partner.partnerId)
          })
          .catch(e => {
            console.log(e)
            this.$toast.error(`Une erreur est survenue.\nImpossible de modifier l'interlocuteur`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
        // this.initialize()
        // this.fetchPartners()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier l'interlocuteur`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    editItem(item) {
      this.editPartnerInterlocutor = Object.assign({}, item)
      this.dialogPutInterlocutor = true
    },

    async deleteInterlocutor(interlocutorId) {
      const idAgency = this.$store.state.user.agency_id

      try {
        await fetch(
          `${config.apiUri}/agencies/${idAgency}/partners/${this.partner.partnerId}/interlocutors/${interlocutorId}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          },
        )
          .then(() => {
            this.$toast.info(`L'interlocuteur à bien été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
          .finally(() => {
            this.fetchPartnerById(this.partner.partnerId)
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer l'interlocuteur`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async postPartnerInterlocutor(data) {
      const idAgency = this.$store.state.user.agency_id

      try {
        await fetch(`${config.apiUri}/agencies/${idAgency}/partners/${this.partner.partnerId}/interlocutors`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            lastname: data.lastname,
            firstname: data.firstname,
            mail: data.mail,
            phone: data.phone,
            cellphone: data.cellphone,
            function: data.function,
          }),
        }).finally(() => {
          this.fetchPartnerById(this.partner.partnerId)
        })

        // this.fetchInterlocutorsByPartnerId(data.partnerId)
        this.dialogAddInterlocutor = false

        this.partnerInterlocutor = {
          lastname: "",
          firstname: "",
          mail: "",
          phone: "",
          cellphone: "",
          function: "",
          partnerId: "",
        }
        this.$toast.success(`Vous avez ajouté à votre liste de partenaire`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    downloadFile2(url) {
      //create blob document and download it
      const link = document.createElement("a")
    },
    downloadFile(url) {
      //create blob document and download it

      const link = document.createElement("a")
      link.href = url
      link.target = "_blank"
      link.download = "Formulaire.pdf" // Remplacez 'nom-du-document' par le nom souhaité pour le fichier téléchargé
      link.click()
    },

    async putProductDescription() {
      let idAgency = this.$store.state.user.agency_id

      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.partner.id
          }&description=${encodeURIComponent(this.partner.description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.fetchProductById(this.partner.id)

          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putProductProfessionnalDescription() {
      let idAgency = this.$store.state.user.agency_id
      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.partner.id
          }&professionnalDescription=${encodeURIComponent(this.partner.professionnal_description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.fetchProductById(this.partner.id)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putProductParticularDescription() {
      let idAgency = this.$store.state.user.agency_id
      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.partner.id
          }&particularDescription=${encodeURIComponent(this.partner.particular_description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.fetchProductById(this.partner.id)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async deleteFile(insurer, indexDocument) {
      insurer.documents.splice(indexDocument, 1)

      const formData = new FormData()
      formData.append("place", "deleteProduct")
      formData.append("idAgencyProduct", this.partner.id)
      formData.append("idAgencyPartner", insurer.id)
      formData.append("data", JSON.stringify(insurer.documents))
      formData.append("indexDelete", indexDocument)

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    async upload(insurer, index) {
      this.partner.insurers[index].dialogValidation = false
      let agencyName = localStorage.getItem("agency").toLowerCase().replace(" ", "_")

      insurer.documents.push({
        url: `${config.ged}/${agencyName}/products/${this.partner.name.toLowerCase().replace(" ", "_")}/${
          this.document.data.name
        }`,
        name: this.document.data.name,
        type: this.document.data.type,
        size: this.document.data.size,
        date: new Date().toLocaleDateString(),
      })
      let documentName = this.document.data.name
      console.log(insurer.documents)

      let extension = this.getExtension(this.document.data.type)

      switch (extension) {
        case "pdf":
          // this.document.name =
          //   "formulaire_déclaration_risque_" + this.partner.name.replace(/ /g, "_").toLowerCase() + "." + extension
          this.fileUrl = URL.createObjectURL(this.document.data)

          let headers = new Headers()
          headers.append("Access-Control-Allow-Origin", "*")

          const formData = new FormData()
          formData.append("product", this.partner.name.toLowerCase().replace(" ", "_"))
          formData.append("agencyName", agencyName)
          formData.append("documentName", documentName)
          formData.append("company", insurer.name.toLowerCase().replace(" ", "_"))
          formData.append("place", "addProduct")
          formData.append("idAgencyProduct", this.partner.id)
          formData.append("idAgencyPartner", insurer.id)
          formData.append("document", this.document.data)
          formData.append("data", JSON.stringify(insurer.documents))

          try {
            const res = await fetch(`${config.apiUri}/upload`, {
              method: "POST",
              body: formData,
            })
            if (res.ok) {
            } else {
              alert("Erreur lors de l'upload du PDF")
            }
          } catch (err) {
            console.error(err)
            alert("Erreur lors de l'upload du PDF")
          }
          break
        default:
          this.$toast.error(`Le format du fichier n'est pas valide\nLe type de fichier accepté est pdf`, {
            position: "bottom-right",
            timeout: 3000,
          })
          return
      }

      this.fileUrl = ""
    },
    onImgError() {
      this.imgError = true
    },
    openFileExplorer(index) {
      const input = document.createElement("input")

      input.type = "file"
      // only word docx pdf
      input.accept = ".pdf"
      input.multiple = true
      input.addEventListener("change", event => {
        this.document.data = event.target.files[0]
        this.partner.insurers[index].dialogValidation = true
        console.log("ouverture")

        // for (let i = 0; i < event.target.files.length; i++) {
        //   const documente = event.target.files[i]
        //   const iframe = document.createElement("iframe")
        //   // iframe width
        //   iframe.width = "100%"
        //   iframe.src = URL.createObjectURL(documente)
        //   document.getElementById(`${insurer.name}`).appendChild(iframe)
        // }
      })
      input.click()
    },
    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    getExtension(fileType) {
      let extension = fileType.split("/")[1]
      return extension
    },
    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileUrl = URL.createObjectURL(this.document.data)
            break
          default:
            this.fileSelected = false
            this.$toast.error(`Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf`, {
              position: "bottom-right",
              timeout: 3000,
            })
        }
      }
    },
    async putInformations(idProduct, data, typology) {
      let idAgency = this.$store.state.user.agency_id
      let formData = new FormData()
      formData.append("idProduct", idProduct)
      formData.append("idAgency", idAgency)
      formData.append("informations", JSON.stringify(data))
      typology === "particular"
        ? formData.append("typology", "particular")
        : formData.append("typology", "professionnal")

      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/products/${idProduct}`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le produit à été modifié.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })

        this.fetchProductById(idProduct)

        this.$forceUpdate()
      } catch (e) {}
    },
    updateValue(guaranteeIndex, valueIndex, newValue) {
      this.item.guarantees[guaranteeIndex].valueInsurer[valueIndex] = newValue
    },
    addLine(array, insurerLength) {
      let valueInsurer = []
      for (let i = 0; i < insurerLength; i++) {
        valueInsurer.push("")
      }

      array.push({
        name: "",
        valueInsurer: valueInsurer,
      })
    },

    async fetchPartnerById(idPartner) {
      this.waitTimeout = true
      this.productsSponsoring = []
      this.loading = true
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/${idPartner}/informations`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        if (response.status === 200) {
          const data = await response.json()
          this.partner = data

          this.waitTimeout = false
        } else {
          this.waitTimeout = false
        }

        this.waitTimeout = false
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>

<style>
.hover-card-interlocutor:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}
.description {
  margin-top: 100px !important;
}
.theme--light.company-table th {
  background-color: #001f47 !important;
  font-size: 14px !important;
  color: #fcc03c !important;
}

.title {
  font-size: 0.875rem !important;
  font-weight: 1500 !important;
}

.hovered-input input {
  cursor: pointer !important;
}

.hovered-input .v-input__slot:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.3s ease-in-out;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 1;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.profilAgencyAvatar {
  margin-bottom: 450px !important;
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.test {
  /*  remove bg color */
  background-color: transparent !important;
}
</style>
